body {
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.header-section {
  background-color: var(--palette-color1);
  color: var(--palette-color3);
}

.skills-section {
  background-color: var(--palette-color2);
  color: var(--palette-color1);
}

.resume-section {
  background-color: var(--palette-color5);
  color: var(--palette-color3);
}

.projects-section {
  background-color: var(--palette-color4);
  color: var(--palette-color1);
}
